import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

import theme from 'theme'
import List from 'List'
import LinkTypography from 'Links'

const NavMenuList = styled(List)`
  margin: 0;

  li {
    margin-right: 2rem;

    &:last-child {
      margin-right: 0;
    }
  }

  @media (min-width: ${theme.device.medium}) {
    display: inline;
  }

  @media (max-width: ${theme.device.medium}) {
    display: none;
  }

`

export default class extends React.Component {

  render() {

    const menu = this.props.data

    return(

      <NavMenuList>
        {menu.map((item,i) =>
          <li key={i}>
            <LinkTypography>
              <Link to={'/' + item.slug}>{item.title}</Link>
            </LinkTypography>
          </li>
        )}
      </NavMenuList>

    )
  }

}
