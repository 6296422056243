import React from 'react'
import styled from 'styled-components'

import theme from 'theme'
import Layout from 'Layout'
import List from 'List'

const FilterLayout = styled(Layout)`
  display: flex;
  justify-content: center;
  align-self: space-between;
  margin: 1rem auto;

  @media (max-width: ${theme.device.small}) {
    display: none;
  }
`

const activeFilterClass = 'activeFilter'

const FilterList = styled(List).attrs({
    activeFilterClass,
  })`

  .${activeFilterClass} {
    border-bottom: 4px solid ${theme.color.grey.lightest};
    font-weight: bold;
    color: ${theme.color.grey.medium};
    padding-bottom: 0.4rem;
  }

  li {
    margin-right: 1rem;
    text-transform: uppercase;

    &:last-child {
      margin-right: 0;
    }

    a {
      transition: all 0.3 ease-in-out;
      padding: 0 5rem;

      &:hover {
        border-bottom: 4px solid ${theme.color.mint.primary};
        color: ${theme.color.mint.primary};
        padding-bottom: 0.4rem;
      }

      @media (max-width: ${theme.device.medium}) {
        padding: 0 1rem;
      }

    }
  }
`

export default class extends React.Component {

  render() {

    return (

      <FilterLayout container div>
        <FilterList {...this.props} >
          {this.props.children}
        </FilterList>
      </FilterLayout>

    )

  }

}
