import React from 'react'

import SiteTitle from 'SiteTitle'
import Menu from 'Menu'
import Header from 'Header'
import Builder from 'Builder'
import PortfolioGrid from 'PortfolioGrid'
import ContactPanelWithParallax from 'ContactPanelWithParallax'
import LogoGridPanel from 'LogoGridPanel'
import Footer from 'Footer'

import getData from 'Data'

class Portfolio extends React.Component {

  render() {

    const {
      data,
      dataGlobal,
      dataAllCaseStudies,
    } = this.props

    return (

      <main>
        <SiteTitle>Inspire Group</SiteTitle>
        <Menu data={dataGlobal.menus} />
        <Header data={data} />
        <Builder data={data}/>
        <PortfolioGrid data={dataAllCaseStudies}/>
        <ContactPanelWithParallax data={data} />
        <LogoGridPanel data={dataGlobal.options}/>
        <Footer />
      </main>

    )

  }

}

export default getData('portfolio', 'Portfolio')(Portfolio)
