const filters = [
  {
    name: 'All',
    slug: 'all',
  },
  {
    name: 'Blended',
    slug: 'blended',
  },
  {
    name: 'Print',
    slug: 'print',
  },
  {
    name: 'Digital',
    slug: 'digital',
  },
  {
    name: 'Workshops',
    slug: 'workshops',
  },
];

export default filters;
