import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import ScrollVisibilityHeader from 'ScrollVisibilityHeader'

import theme from 'theme'
import NavMenuList from 'NavMenuList'
import PrimaryLogo from '../elements/icons/Logo'
import MenuInModal from 'MenuInModal'

const Logo = styled(PrimaryLogo)`
  height: auto;
  width: 140px;

  color: ${theme.color.black};
  transition: all 0.3 ease-in-out;
  margin-left: 2rem;

  &:hover {
    color: ${theme.color.mint.primary};
  }
`

const LogoText = styled.span`
  display: none;
`

const LogoContainer = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
`

const MenuContainer = styled.div`
  position: relative;
  background-color: ${theme.color.white};

  & .headroom {
    border-color: ${theme.color.white};
    background-color: ${theme.color.white};
    &.headroom--unfixed {
      border-color: transparent;
    }
    &.headroom--unpinned {
      background-color: ${theme.color.white};
    }
    & .background--white {
      background-color: ${theme.color.white};
    }
  }

  ${props => props.transparent && `
    position: absolute;
    top: 0;
    left: 0;
    right: 0;

    .headroom {
      background-color: transparent;
    }
  `}

  ${props => props.border && `
    & .headroom {
      border-color: ${theme.color.black};
      &.headroom--unfixed {
        border-color: ${theme.color.black};
      }
      &.headroom--unpinned {
        border-color: ${theme.color.white};
      }
    }
  `}

`

const MenuBar = styled.header`
  height: 100%;
`

const MenuBarContent = styled.nav`
  max-width: ${theme.layout.width};
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-basis: 100%;
  justify-content: space-between;
  align-items: center;
  height: 100%;
`

const MobileMenu = styled.div`
  align-items: center;

  @media (min-width: ${theme.device.medium}) {
    display: none;
  }

  @media (max-width: ${theme.device.medium}) {
    display: flex;
  }
`

class MenuNavBar extends React.Component {

  render() {

    const menu = this.props.data

    return (

      <MenuContainer {...this.props} >
        <ScrollVisibilityHeader disableInlineStyles={true} >
          <MenuBar>
            <MenuBarContent>
              <LogoContainer>
                <Link to={'/'}>
                  <Logo/>
                  <LogoText>
                    Inspire Group
                  </LogoText>
                </Link>
              </LogoContainer>
              <NavMenuList data={menu.mainMenu} />
              <MobileMenu>
                <MenuInModal data={menu.mainMenu} />
              </MobileMenu>
            </MenuBarContent>
          </MenuBar>
        </ScrollVisibilityHeader>
      </MenuContainer>
    )

  }

}

export default MenuNavBar;
