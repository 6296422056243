import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import FadeIn from 'LazyLoad';

import theme from 'theme';
// import Layout from 'Layout';
import Card from 'Card';
import Typography from 'Typography';
// import TextCard from 'TextCard';

import placeholder from 'static/images/placeholder-square.png';

const ItemText = styled(Card)`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  padding-bottom: 20%;
  opacity: 0;
  background-color: rgba(0, 0, 0, 0.8);
  transition: opacity 0.2s linear;
`;

const ItemImage = styled.img`
  position: relative;
  max-width: 100%;
  height: auto;
  background-color: ${theme.color.grey.lightest};
`;

const GridCard = styled(Card)`
  &:hover ${ItemText} {
    opacity: 1;
  }
`;

function dateConversion ( dateString ){
  const date = new Date(Date.parse(dateString));
  const locale = "en-us";
  return date.toLocaleString(locale, {
    month: "long",
    day: "numeric",
    year: "numeric"
  });
}

export default class GridItemPeople extends React.Component {

  render() {

    const wp = this.props.data;
    let image; // Get featured image or else use placeholder
    !wp.media ? image = placeholder : image = wp.media.grid2x;

    return (

      <Link to={'/blog/' + wp.slug}>
        <GridCard>
          <ItemImage
            src={image}
            width="1200"
            height="1200"
            alt="Inspire Group"
          />
          <ItemText absolute>
            <Typography
              bold
              uppercase
              centre
              colorWhite
              noMargin
              paddingLR
            >
              {wp.title}
            </Typography>
            <Typography
              display6
              colorWhite
              centre
              noMargin
              paddingLR
            >
              {dateConversion(wp.date)}
            </Typography>
          </ItemText>
        </GridCard>
      </Link>
    );

  }

}

export const GridItemWithTransition = (props) => (
    <FadeIn height={290} duration={500}>
      {onload => <GridItemPeople data={props.data} onLoad={onload}/>}
    </FadeIn>
);
