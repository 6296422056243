import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import theme from 'theme';
import Layout from 'Layout';
import Card from 'Card';
// import Image from 'Image';
import Button from 'Button';
import List, { Item } from 'List';
import Typography from 'Typography';

const ColumnsLayout = styled(Layout)`
  padding-top: 4rem;
`;

const ColumnsListCard = styled(Card)``;

const ColumnsList = styled(List)`
  display: flex;
  justify-content: space-between;
  text-align: center;

  @media (max-width: ${theme.device.medium}) {
    flex-direction: column;
  }

  img {
    width: auto;
    height: 140px;
  }

  ${ColumnsListCard} {
    padding: 2rem;

    p {
      max-width: 300px;
      margin-left: auto;
      margin-right: auto;
    }

    button {
      margin-top: 2rem;
    }
  }
`;

function getUrlPath (string) {

  const url = new URL(string);
  return url.pathname;

}

export default class extends React.Component {

  render() {

    const wp = this.props.data;

    return (

      <ColumnsLayout container>
        <ColumnsList>
          <Item>
            <ColumnsListCard>
              <img
                src={wp.acf.home_columns_panel_left.image.url}
                alt={wp.acf.home_columns_panel_left.image.alt}
                title={wp.acf.home_columns_panel_left.image.title}
              />
              <Typography
                bold
                heading3
                display3
              >
                {wp.acf.home_columns_panel_left.heading}
              </Typography>
              <Typography
                noMargin
              >
                {wp.acf.home_columns_panel_left.text}
              </Typography>
              <Link to={getUrlPath(wp.acf.home_columns_panel_left.link.url)}>
                <Button>
                  More
                </Button>
              </Link>
            </ColumnsListCard>
          </Item>
          <Item>
            <ColumnsListCard>
              <img
                src={wp.acf.home_columns_panel_centre.image.url}
                alt={wp.acf.home_columns_panel_centre.image.alt}
                title={wp.acf.home_columns_panel_centre.image.title}
              />
              <Typography
                bold
                heading3
                display3
              >
                {wp.acf.home_columns_panel_centre.heading}
              </Typography>
              <Typography
                noMargin
              >
                {wp.acf.home_columns_panel_centre.text}
              </Typography>
              <Link to={getUrlPath(wp.acf.home_columns_panel_centre.link.url)}>
                <Button>
                  More
                </Button>
              </Link>
            </ColumnsListCard>
          </Item>
          <Item>
            <ColumnsListCard>
              <img
                src={wp.acf.home_columns_panel_right.image.url}
                alt={wp.acf.home_columns_panel_right.image.alt}
                title={wp.acf.home_columns_panel_right.image.title}
              />
              <Typography
                bold
                heading3
                display3
              >
                {wp.acf.home_columns_panel_right.heading}
              </Typography>
              <Typography
                noMargin
              >
                {wp.acf.home_columns_panel_right.text}
              </Typography>
              <Link to={getUrlPath(wp.acf.home_columns_panel_right.link.url)}>
                <Button>
                  More
                </Button>
              </Link>
            </ColumnsListCard>
          </Item>
        </ColumnsList>
      </ColumnsLayout>
    );

  }

}
