import React, {Fragment} from 'react'
import styled from 'styled-components'
import FadeIn from 'LazyLoad'

import theme from 'theme'
// import Layout from 'Layout'
import Card from 'Card'
import Typography from 'Typography'

import placeholder from 'static/images/placeholder-square.png'

const ItemOverlay = styled(Card)`
  opacity: 0;
  position: relative;
  width: 100%;
  height: 100%;
`;

const ItemText = styled(Card)`
  display: block;
  width: 100%;
  height: 100%;
`;

const TextCard = styled(Card)`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  padding-bottom: 2rem;
  background-color: rgba(0, 0, 0, 0.5);

`;

const ItemImage = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  max-width: 100%;
  height: auto;
  background-color: ${theme.color.grey.lightest};
`;

const GridCard = styled(Card)`
  cursor: pointer;
  width: 100%;
  height: 100%;

  &:hover ${ItemOverlay} {
    transition: all 0.3s ease-in-out;
    opacity: 1;
  }
`;

export default class GridItemPeople extends React.Component {

  render() {

    const wp = this.props.data;
    let imageMain, imageHover; // Get featured image or else use placeholder
    !wp.acf.people_main_image ? imageMain = placeholder : imageMain = wp.acf.people_main_image.sizes.grid2x;
    !wp.acf.people_hover_image ? imageHover = placeholder : imageHover = wp.acf.people_hover_image.sizes.grid2x;

    return (

      <Fragment>
        <GridCard>
          <ItemImage
            src={imageMain}
            width="500"
            height="500"
            alt="Inspire Group"
          />
          <ItemOverlay>
            <ItemImage
              src={imageHover}
              width="500"
              height="500"
              alt="Inspire Group"
            />
            <ItemText>
              <TextCard>
                <Typography
                  bold
                  colorWhite
                  centre
                  noMargin
                  paddingLR
                >
                  {wp.title}
                </Typography>
                <Typography
                  display6
                  colorWhite
                  centre
                  noMargin
                  paddingLR
                >
                  {wp.acf.people_position}
                </Typography>
              </TextCard>
            </ItemText>
          </ItemOverlay>
        </GridCard>
      </Fragment>
    );

  }

}

export const GridItemWithTransition = (props) => (
    <FadeIn height={290} duration={500}>
      {onload => <GridItemPeople data={props.data} onLoad={onload}/>}
    </FadeIn>
);
