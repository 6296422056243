import React, { Fragment } from 'react'

import Layout from 'Layout'
import Typography from 'Typography'

export default class BuilderTextPanel extends React.Component {

  render() {

    const { data } = this.props

    let isLargeText
    data.text_size === 'normal' ? isLargeText = false : isLargeText = true

    return (

      <Fragment>
        <Layout container narrow padding={'3rem 0 3rem 0'}>
          <Typography
            heading2
            display3
            bold
            centre
            noMargin
            dangerouslySetInnerHTML={{ __html:data.heading}}
          />
          <Typography
            div
            display4={isLargeText}
            centre
            dangerouslySetInnerHTML={{ __html:data.text}}
          />
        </Layout>
      </Fragment>

    )

  }

}
