import React from 'react';
import styled from 'styled-components';

import theme from 'theme';
import Typography from 'Typography';

const LinkTypography = styled(Typography)`
  position: relative;
  display: inline-block;

  a {
    color: ${theme.color.black};
    font-size: ${theme.font.display6};
    font-weight: bold;
    letter-spacing: 0.5px;
    line-height: 1rem;
    text-transform: uppercase;
    border-bottom: none;
    position: relative;
    text-align: inherit;

    &::after{
      width: 0rem;
      display: block;
      content: "";
      position: absolute;
      bottom: -5px;
      border-bottom-width: 2px;
      border-bottom-style: solid;
      border-bottom-color: inherit;
      transition: width 0.3s linear;
    }

    &:hover {
      color: ${theme.color.black};
      border: none;
      border-bottom-color: ${theme.color.black};
      padding: 0;

      &::after {
        border-bottom-color: inherit;
        width: 100%;
      }
    }
  }
`;

export default class extends React.Component {

  render() {

    return(

      <LinkTypography {...this.props}>
        {this.props.children}
      </LinkTypography>

    )
  }

};
