import React from 'react';
// import styled from 'styled-components';

// import theme from 'theme';
import Layout from 'Layout';
import Typography from 'Typography';

class HomeTextPanel extends React.Component {

  render() {

    const data = this.props.data.acf;

    return (

      <div>
        <Layout container>
          <Typography
            display2
            heading2
            bold
            centre
            noMargin
            dangerouslySetInnerHTML={{ __html:data.home_text_panel_heading}}
          />
        </Layout>
        <Layout container narrow>
          <Typography
            centre
            dangerouslySetInnerHTML={{ __html:data.home_text_panel_text_content}}
          />
        </Layout>
      </div>

    );

  }

}

export default HomeTextPanel;
