import React from 'react';
import styled from 'styled-components';

import theme from 'theme';
import Layout from 'Layout';

import Filter from 'FilterByTerms';
import GridItemWithTransition from 'GridItemPeople';

import filters from 'static/data/peopleFilters.js';

const Grid = styled.ul`
  display: grid;
  grid-template-columns: 20% 20% 20% 20% 20%;
  gap: 2px;
  padding-bottom: 10rem;

  @media (max-width: ${theme.device.large}) {
    grid-template-columns: 25% 25% 25% 25%;
  }

  @media (max-width: ${theme.device.medium}) {
    --item: calc(100% / 3);
    grid-template-columns: var(--item) var(--item) var(--item);
  }

  @media (max-width: ${theme.device.small}) {
    grid-template-columns: 50% 50%;
  }

  @media (max-width: ${theme.device.smallest}) {
    grid-template-columns: 100%;
  }

  @media (min-width: ${theme.device.largest}) {
    --item: calc(100% / 6);
    grid-template-columns: var(--item) var(--item) var(--item) var(--item) var(--item) var(--item);
  }

`;

const Item = styled.li`
  width: 100%;
  height: 100%;
  position: relative;
  font-size: 0;

  &::before {
    content: "";
    float: left;
    display: inline-block;
    width: 1px;
    margin-left: -1px;
    height: 0;
    padding-top: 100%;
  }
  &::after {
    content: "";
    display: table;
    clear: both;
  }

  @media (max-width: ${theme.device.small}) {
    flex-basis: 100%;
  }

`;

export default class extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      filteredData: [],
    }
  }

  projectFilteredData = (dataFromChild) => {
    this.setState = {
      filteredData: dataFromChild,
    }
  }

  onUpdate (data) {
    this.setState({
      filteredData: data,
    })
  }

  render() {

    let data = this.props.data; // Initial data
    const { filteredData } = this.state; // Updated & filtered data

    if (filteredData.length) {
      data = filteredData;
    }

    return (

      <Layout>
        <Filter
          onUpdate={this.onUpdate.bind(this)}
          filters={filters}
          {...this.props}
        />
        <Grid>
          {data.map((item,i) =>
            <Item key={i}>
              <GridItemWithTransition data={item} />
            </Item>
          )}
        </Grid>
      </Layout>

    );

  }

}
