import React from 'react';
import styled from 'styled-components';
import Carousel from 'nuka-carousel';
import 'static/styles/carousel.css';

import Layout from 'Layout';
import Image from 'Image';

// This slide is basically a container - any styling done on internal elements
const Slide = styled.div`
  width: 100%;
  height: auto;
  justify-content: center;
  font-size: 0;
  position: relative;
`;

// This and using state to force render gets around bug in nuka-carousel initial height
function getInitialHeight(data) {

  const container = document.body.clientWidth; // Assuming full width carousel
  const height = data[0].slide.height;
  const width = data[0].slide.width;

  return Math.round(height / width * container);

}

export default class extends React.Component {

  constructor(props) {

    super(props);
    this.state = {
      carouselData: [],
      initialHeight: null,
    };

  }

  setStateAsync(state) {
    return new Promise((resolve) => {
      this.setState(state, resolve)
    });
  }

  async componentDidMount() {

    const data = await this.props.data.slides;
    const height = await getInitialHeight(data);

    await this.setStateAsync(
      {
        carouselData: data,
        initialHeight: height,
      }
    );

  }

  render() {

    const { carouselData } = this.state;
    const { initialHeight } = this.state;

    const settings = {
      wrapAround: true,
      slidesToShow: 1,
      cellSpacing: 0,
      withoutControls: false,
      transitionMode: 'fade',
      heightMode: 'first',
      initialSlideHeight: initialHeight,
    };

    return (

      <Layout style={{padding: '3rem 0 0 0'}} >
        <Carousel {...settings}>
          {carouselData.map((item, index) => (
            <Slide key={index}>
              <Image data={item.slide} />
            </Slide>
          ))}
        </Carousel>
      </Layout>

    );

  }

}
