import React from 'react'

import SiteTitle from 'SiteTitle'
import Menu from 'Menu'
import Header from 'Header'
import Builder from 'Builder'
import BlogGrid from 'BlogGrid'
import CarouselMultipleSlides from 'CarouselMultipleSlides'
import LogoGridPanel from 'LogoGridPanel'
import Footer from 'Footer'

import getData from 'Data'

class Blog extends React.Component {

  render() {

    const {
      data,
      dataAllPosts,
      dataGlobal,
    } = this.props

    return (

      <main>
        <SiteTitle>Inspire Group</SiteTitle>
        <Menu data={dataGlobal.menus} />
        <Header data={data} />
        <Builder data={data}/>
        <BlogGrid data={dataAllPosts}/>
        <CarouselMultipleSlides title={'Our work'} data={dataGlobal.options} />
        <LogoGridPanel data={dataGlobal.options} />
        <Footer />
      </main>

    )

  }

}

export default getData('blog', 'Page')(Blog)
