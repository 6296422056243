import React from 'react'

import Filter from 'Filter'

// Pass in all posts data and id being looked for
// Loop through each post item
// Use filterTerms to determine if there is a term matching id
// Rebuild data with items (posts) that contain the id of the filter
// Update state to re-render
function filterByTerm (data, id) {

  if (id === '1') {
    return data
  } else {
    return data.filter(
      item => {
        return filterTerms(item.category_ids, id)
      }
    )
  }

}

// Pass in all category IDs that the post has assigned
// Determine if the required filter item ID is present
// Return true or false to build new data array of posts
function filterTerms (terms, item) {

  let success = false

  if (terms) {
    terms.forEach((term)=> {
      if (term === parseInt(item) ) {
        success = true
      }
    })
  }

  return success
}

export default class extends React.Component {

  state = {
    defaultFilter: true,
  }

  handleFilter = (filter, event) => {

    // Find all filters in the list and remove active status
    document.querySelectorAll('.Filter').forEach((filter) => {
      filter.classList.remove('activeFilter')
    })

    // Process data based on selected filter term (by ID not slug)
    this.props.onUpdate(filterByTerm(this.props.data, filter))

    // Update this clicked on filter to be active
    event.target.classList.add('activeFilter')

    this.setState({
      defaultFilter: false,
    })
    event.preventDefault()
  }

  render() {

    return (

      <Filter>
        {this.props.filters.map(filter => (
          <li key={filter.slug}>
            <a
              href={'/'+filter.slug}
              className={(this.state.defaultFilter && filter.id === '1') ? 'Filter activeFilter' : 'Filter'}
              onClick={this.handleFilter.bind(this, filter.id)}
            >
              {filter.name}
            </a>
          </li>
        ))}
      </Filter>

    )

  }

}
