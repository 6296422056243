import React from 'react'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import { ModalProvider } from 'Modal'

// View templates for specific routes
import Home from 'Home'
import Contact from 'Contact'
import WhoWeAre from 'WhoWeAre'
import Portfolio from 'Portfolio'
import Blog from 'Blog'
import Error from '404'

// View templates for dynamic routes
import Page from 'Page' // Fallback or default template
import CaseStudy from 'CaseStudy'
import BlogPost from 'BlogPost'
import Campaign from 'Campaign'

class App extends React.Component {

  render() {

    // Remove loading screen (blank screen until virtual DOM is ready)
    // Relies on WordPress isUserLoggedIn() to output class into <body>
    // so if user logs in they need to manually reload page however this will
    // only ever be company staff so not affecting UX for general population
    document.getElementById('loading').style.display = 'none'

    // Vanity, tidy up body tag for general public
    if ( document.body.getAttribute('class') !== 'isLoggedIn')
      document.body.removeAttribute('class')

    return (

      <ModalProvider>
        <BrowserRouter>
          <Switch>
            <Route exact path="/" component={Home} />
            <Route path="/who-we-are" component={WhoWeAre} />
            <Route path="/portfolio" component={Portfolio} />
            <Route path="/case-study/:id" component={CaseStudy} />
            <Route exact path='/blog' component={Blog}/>
            <Route path='/blog/:id' component={BlogPost}/>
            <Route path='/campaign/:id' component={Campaign}/>
            <Route path="/contact" component={Contact} />
            <Route path='/:id' component={Page}/>
            {/* <Route
              path="/:id"
              render={(props) => <Page {...props} slug={props.match.params.id} />}
            /> */}
            <Route component={Error} />
          </Switch>
        </BrowserRouter>
      </ModalProvider>

    );
  }
}

export default App
