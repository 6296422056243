import React from 'react'
import styled from 'styled-components'

import theme from 'theme'
import Layout from 'Layout'

import Filter from 'FilterByCategories'
import GridItemWithTransition from 'GridItemPost'

import filters from 'static/data/blogFilters.js'

const Grid = styled.ul`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 2px;

  @media (max-width: ${theme.device.small}) {
    grid-template-columns: repeat(1, 1fr);
  }

  @media (min-width: ${theme.device.largest}) {
    grid-template-columns: repeat(6, 1fr);
  }

  @media (min-width: ${theme.device.mega}) {
    grid-template-columns: repeat(8, 1fr);
  }

`

const Item = styled.li`
  width: 100%;
  position: relative;
  font-size: 0;

  /* Big item on LHS, or at top */
  @media (min-width: ${theme.device.small}) {
    &:first-child {
      grid-column: 1 / 3;
      grid-row: 1 / 3;
    }
  }

  @media (min-width: ${theme.device.small}) and (max-width: ${theme.device.largest}) {
    /* Big item on LHS */
    &:nth-child(11) {
      grid-column: 1 / 3;
      grid-row: 5 / 7;
    }

    /* Big item on RHS */
    &:nth-child(8) {
      grid-column: 3 / 5;
      grid-row: 3 / 5;
    }
  }

  @media (min-width: ${theme.device.largest}) {
    /* Big item on RHS */
    &:nth-child(14) {
      grid-column: 5 / 7;
      grid-row: 3 / 5;
    }
  }

  @media (min-width: ${theme.device.mega}) {
    /* Big item on RHS */
    &:nth-child(14) {
      grid-column: 7 / 9;
      grid-row: 3 / 5;
    }
  }
`

export default class extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      filteredData: [],
    }
  }

  onUpdate (data) {
    this.setState({
      filteredData: data,
    })
  }

  render() {

    let data = this.props.data // Initial data
    // console.log(data)
    const { filteredData } = this.state // Updated & filtered data

    if (filteredData.length) {
      data = filteredData
    }

    return (

      <Layout>
        <Filter
          onUpdate={this.onUpdate.bind(this)}
          filters={filters}
          {...this.props}
        />
        <Grid>
          {data.map((item,i) =>
            <Item key={i}>
              <GridItemWithTransition data={item} />
            </Item>
          )}
        </Grid>
      </Layout>

    )

  }

}
