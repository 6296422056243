import React from 'react';
import styled from 'styled-components';

import theme from 'theme';
// import Layout from 'Layout';
import Card from 'Card';
import Typography from 'Typography';
import Mask from 'Mask';
import VideoInBG from 'VideoInBG';
import VideoInModal from 'VideoInModal';

const HeroLayout = styled.section`
  height: 100vh;
  min-height: 800px;
  width: 100vw;
  display: flex;
  position: relative;
  background-color: ${theme.color.mint.primary};

  @media (max-width: ${theme.device.medium}) {
    height: auto;
    text-align: center;
  }
`;

const HeroCard = styled(Card)`
  z-index: 2;
  margin: auto;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  top: -5vh;
`;

export default class extends React.Component {

  render() {

    const data = this.props.data.acf;

    return (

      <HeroLayout>
        <HeroCard flex >
          <Typography
            heading1
            display2
            bold
          >
            {data.home_hero_heading}
          </Typography>
          <Typography
          >
            {data.home_hero_text}
          </Typography>
          <VideoInModal src={data.home_hero_modal_video} />
        </HeroCard>
        <VideoInBG src={data.home_hero_bg_video} />
        <Mask bottom image src={data.home_hero_gradient}/>
      </HeroLayout>

    );

  }

}
