import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import Plx from 'react-plx';

import theme from 'theme';
import Card from 'Card';
import Button from 'Button';
import Typography from 'Typography';
import Mask from 'Mask';
// import Image from 'Image';

const ContactLayout = styled.section`
  height: 100vh;
  width: 100vw;
  display: block;
  position: relative;
  background-color: ${theme.color.mint.primary};
  ${'' /* background-color: lime; */}
  background-repeat: no-repeat;
  background-size: 100%;
  z-index: 0;

  @media (max-width: ${theme.device.medium}) {
    height: 600px;
  }

  @media (max-width: ${theme.device.small}) {
    height: 50vh;
  }

`;

const ContactCard = styled(Card)`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 20;
  margin: auto;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;


const parallaxDataLayer1 = [
  {
    start: 0,
    end: 4000,
    properties: [
      {
        startValue: 30,
        endValue: -10,
        unit: 'vh',
        property: 'translateY',
      },
    ],
  },
];
const parallaxDataLayer2 = [
  {
    start: 0,
    end: 4000,
    properties: [
      {
        startValue: 40,
        endValue: -20,
        unit: 'vh',
        property: 'translateY',
      },
    ],
  },
];

const ParallaxLayer1 = styled(Plx)`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  overflow: hidden;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;

    @media (max-width: ${theme.device.small}) {
      ${'' /* object-fit: fill; */}
    }
  }
`;
const ParallaxLayer2 = styled(ParallaxLayer1)`
  z-index: -2;

  img {
    @media (max-width: ${theme.device.small}) {
      object-fit: cover;
      width: auto;
      height: 100%;
      overflow: hidden;
    }
  }
`;

export default class extends React.Component {

  render() {

    const wp = this.props.data;

    return (

      <ContactLayout
        className="parallax"
        style={{backgroundImage: `url(${wp.acf.home_contact_panel_bg_image.url})`}}
      >
        <ContactCard flex>
          <Typography
            heading2
            display3
            noMargin
            lineHeight={'1rem'}
          >
            {wp.acf.home_contact_panel_heading}
          </Typography>
          <Typography
            display2
            bold
            noMargin
            centre
            // lineHeight={'1rem'}
            dangerouslySetInnerHTML={{ __html:wp.acf.home_contact_panel_text_content}}
          />
          <Link to={'/contact'}>
            <Button secondary margin={"1rem 0 0"}>
              Get in touch
            </Button>
          </Link>
        </ContactCard>
        <ParallaxLayer1
          parallaxData={ parallaxDataLayer1 }
        >
          <img
            src={wp.acf.home_contact_panel_layer1_image.url}
            alt={''}
          />
        </ParallaxLayer1>
        <ParallaxLayer2
          parallaxData={ parallaxDataLayer2 }
        >
          <img
            src={wp.acf.home_contact_panel_layer2_image.url}
            alt={''}
          />
        </ParallaxLayer2>
        <Mask transparent/>
      </ContactLayout>

    );

  }

}
