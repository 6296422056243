import React, { Fragment } from 'react'
import styled from 'styled-components'

import theme from 'theme'
import Typography from 'Typography'
import VideoInBG from 'VideoInBG'

import HeaderParallax from 'HeaderParallax'

const HeaderLayout = styled.header`
  height: 500px;
  width: 100vw;
  position: relative;
  display: block;
  overflow: hidden;
  background-color: ${theme.color.white};
  z-index: 2;
`;

const Title = styled(Typography)`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 4;
`;

const ImageContainer = styled.div`
  position: relative;
  width: 100%;
  height: 500px;
`;

const ImageInFront = styled.img`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  display: flex;
  margin: auto;
  height: 100%;
  width: auto;
  z-index: 3;
`;

const ImageInBG = styled.div`
  width: 100%;
  min-height: 100%;
  z-index: 2;

  background-image: url(${props => props.src && props.src});
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
`;

export default class extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      error: null,
      hasBg: false,
      isLoaded: false,
      isVideo: false,
      isParallax: false,
      textColor: theme.color.white,
    };
  }

  componentDidMount() {

    if (this.props.data.acf.header_background !== 'none') {
      this.setState({hasBg: true});
    }

    if (this.props.data.acf.header_parallax === 'on') {
      this.setState({isParallax: true});
    }

    if (this.props.data.acf.header_background === 'video') {
      this.setState({isVideo: true});
    }

    if (this.props.data.acf.header_text_colour === 'black') {
      this.setState({textColor: theme.color.black});
    }

    if (this.props.data.acf.header_text_colour === 'mint') {
      this.setState({textColor: theme.color.mint.primary});
    }

  }

  render() {

    const wp = this.props.data;

    return (

      <HeaderLayout>
        { this.state.hasBg
          ?
            ( this.state.isVideo
              ?
              <VideoInBG src={wp.acf.header_video} />
              :
              <ImageContainer>
                { this.state.isParallax
                  ?
                  <Fragment>
                    <HeaderParallax
                      data={wp.acf}
                    />
                  </Fragment>
                  :
                  <Fragment>
                    { wp.acf.header_image_bg &&
                      <ImageInBG
                        src={wp.acf.header_image_bg.url}
                      />
                    }
                    { wp.acf.header_image_front &&
                      <ImageInFront
                        src={wp.acf.header_image_front.url}
                        alt={wp.acf.header_image_front.alt}
                        title={wp.acf.header_image_front.title}
                        width={wp.acf.header_image_front.width}
                        height={wp.acf.header_image_front.height}
                      />
                    }
                  </Fragment>
                }
              </ImageContainer>
            )
          : null
        }
        <Title
          heading1
          display2
          bold
          centre
          color={this.state.textColor}
        >
          {wp.title}
        </Title>
      </HeaderLayout>

    );

  }

}
