import React from 'react';

import Layout from 'Layout';
import Typography from 'Typography';

class TitlePanel extends React.Component {

  render() {

    return (

      <div {...this.props}>
        <Layout container>
          <Typography
            display3
            heading2
            bold
            centre
            noMargin
          >
            {this.props.children}
          </Typography>
        </Layout>
      </div>

    );

  }

}

export default TitlePanel;
