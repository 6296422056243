import React from 'react';
import styled from 'styled-components';

import theme from 'theme';
import Layout from 'Layout';
import Image from 'Image';
// import Typography from 'Typography';
import TextCard from 'TextCard';

const Grid = styled(Layout)`
  display: flex;
  flex-direction: row;
  font-size: 0;

  @media (max-width: ${theme.device.medium}) {
    flex-direction: column;
  }
`;

const Item = styled.div`
  flex-basis: 50%;
  flex-direction: column;
  justify-content: center;
  align-self: center;

  @media (max-width: ${theme.device.medium}) {
    flex-basis: 100%;
  }

  @media (min-width: ${theme.device.largest}) {
    max-height: 72vh;
  }

`;

const ItemWithText = styled(Item)`
  order: 1;
  order: ${props => props.left && '0'};

  @media (max-width: ${theme.device.medium}) {
    order: 1;
    padding: 4rem 0;
  }

`;

const ItemWithImage = styled(Item)`
  overflow: hidden;
  background-color: ${theme.color.grey.lightest};

  @media (max-width: ${theme.device.medium}) {
    order: 0;
  }

  @media (min-width: ${theme.device.largest}) {
    img {
      max-height: 72vh;
      width: 100%;
      object-fit: cover;
      object-position: center center;
    }
  }

`;

export default class extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      isLeftImage: false,
      isLeftText: false,
    };
  }

  componentDidMount() {
    const { data } = this.props;

    if (data.layout === 'textLeft') {
      this.setState({isLeftText: true});
    }

  }

  render() {

    const { data } = this.props;

    return (

      <Grid>
        {this.state.isLeftText
          ?
          <ItemWithText left>
            <TextCard data={data} />
          </ItemWithText>
          :
          <ItemWithText>
            <TextCard data={data} />
          </ItemWithText>
        }
        <ItemWithImage>
          <Image data={data.image} />
        </ItemWithImage>
      </Grid>

    );

  }

}
