import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import FadeIn from 'LazyLoad';

import theme from 'theme';
import Card from 'Card';
import Typography from 'Typography';

import placeholder from 'static/images/placeholder-square.png';

const ItemText = styled(Card)`
  opacity: 0;
  background-color: rgba(0, 0, 0, 0.8);
  transition: opacity 0.2s linear;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const ItemImage = styled.img`
  position: relative;
  width: 100%;
  height: auto;
  background-color: ${theme.color.grey.lightest};
  transition: transform 0.8s ease-in-out;
`;

const GridCard = styled(Card)`
  overflow: hidden;

  &:hover ${ItemText} {
    opacity: 1;
  }
  &:hover ${ItemImage} {
    transform: scale(1.15);
  }

`;

export default class GridItem extends React.Component {

  render() {

    const wp = this.props.data;
    let image; // Get featured image or else use placeholder
    !wp.media ? image = placeholder : image = wp.media.grid2x;

    return (

      <Link to={'/case-study/' + wp.slug}>
        <GridCard>
            <ItemImage
              src={image}
              width="500"
              height="500"
              alt="Inspire Group"
            />
          <ItemText absolute>
            <Typography
              bold
              colorWhite
              centre
              noMargin
              paddingLR
            >
              {wp.title}
            </Typography>
            <Typography
              display6
              colorWhite
              centre
              noMargin
              paddingLR
            >
              {wp.acf.case_study_description}
            </Typography>
          </ItemText>
        </GridCard>
      </Link>
    );

  }

}

export const GridItemWithTransition = (props) => (
    <FadeIn height={290} duration={500}>
      {onload => <GridItem data={props.data} onLoad={onload}/>}
    </FadeIn>
);
