import React from 'react'
import { withFormik, Field } from 'formik'
import * as Yup from 'yup'
import classnames from 'classnames'
import styled from 'styled-components'

import Layout from 'Layout'
import Button from 'Button'
import Typography from 'Typography'

import 'static/styles/forms.css'

const postUrl = 'https://81qsow8gjb.execute-api.us-west-2.amazonaws.com/dev/static-site-mailer'

const formikWrapper = withFormik({

  // Need to wrap textarea input with error wrapper before setting as required
  validationSchema: Yup.object().shape({
    name: Yup.string()
      .min(1, "Your name is required")
      .required("Your name is required."),
    emailFrom: Yup.string()
      .email("Invalid email address")
      .required("Email is required!"),
    // message: Yup.string()
    //   .min(3, "Please provide a short message")
    //   .required("Please provide a short message."),
  }),

  mapPropsToValues: ({ user }) => ({
    ...user
  }),

  handleSubmit: (payload, { setSubmitting, resetForm }) => {
    var xhr = new XMLHttpRequest()
    xhr.open('POST', postUrl, true)
    xhr.setRequestHeader('Accept', 'application/json; charset=utf-8')
    xhr.setRequestHeader('Content-Type', 'application/json; charset=UTF-8')
    xhr.send(JSON.stringify(payload, null, 2))
    setSubmitting(false)

    xhr.onloadend = response => {
      // Use CSSTransitionGroup for something nicer
      const form = document.querySelector('#contactForm')
      const formTitle = document.querySelector('#formGreeting')
      const formResponse = document.querySelector('#formResponse')
      if (response.target.status === 200) {
        resetForm()
        formTitle.classList.add('isAnimatedHidden')
        form.classList.add('isAnimatedHidden')
        formResponse.innerHTML = 'Thanks for the message. We’ll be in touch shortly.';
      } else {
        resetForm()
        formResponse.innerHTML = 'Something went wrong'
        console.error(JSON.parse(response.target.response).message)
      }
    }
  },

})

const InputFeedback = ({ error }) =>
  error ? <div className="input-feedback">{error}</div> : null

const Label = ({ error, className, children, ...props }) => {
  return (

    <label className="label" {...props}>
      {children}
    </label>
  )

}

let TextInput = styled.input`
  width: 100%;
  line-height: 2rem;
  border: none;
`

TextInput = ({
    type,
    id,
    label,
    error,
    value,
    onChange,
    className,
    ...props
  }) => {

  const classes = classnames(
    {
      "error": !!error,
    },
    )

  return (

    <div className={classes}>
      <Label htmlFor={id} error={error}>
        {label}
      </Label>
      <input
        id={id}
        className="text-input"
        type={type}
        value={value}
        onChange={onChange}
        {...props}
      />
      <InputFeedback error={error} />
    </div>

  )

}

const ContactForm = props => {

  const {
    values,
    touched,
    errors,
    // hidden,
    // dirty,
    handleChange,
    handleBlur,
    handleSubmit,
    // handleReset,
    isSubmitting
  } = props

  return (

    <Layout container narrow padding={'3rem 0 0 0'}>
      <Typography
        display3
        bold
        center
      >
        Get in touch
      </Typography>
      <Typography
        id="formGreeting"
        display2
        bold
        noMargin
        center
      >
        Let's see if we can help
      </Typography>
      <Typography
        id="formResponse"
        display2
        bold
        noMargin
        center
      >
      </Typography>
      <form onSubmit={handleSubmit} id="contactForm">
        <div className="isHidden">
          <TextInput
            name="honeypot"
            type="text"
            value=""
            autoComplete="off"
            tabIndex="-1"
            readOnly={true}
          />
          <TextInput
            id="emailAuth"
            type="hidden"
            value=""
          />
          <TextInput
            id="emailTo"
            type="hidden"
            value=""
          />
        </div>

        <TextInput
          id="name"
          type="text"
          placeholder="Name *"
          error={touched.name && errors.name}
          value={values.name}
          onChange={handleChange}
          onBlur={handleBlur}
        />
        <TextInput
          id="subject"
          type="text"
          placeholder="Subject"
          error={touched.subject && errors.subject}
          value={values.subject}
          onChange={handleChange}
          onBlur={handleBlur}
        />
        <TextInput
          id="emailFrom"
          type="email"
          placeholder="Email *"
          error={touched.emailFrom && errors.emailFrom}
          value={values.emailFrom}
          onChange={handleChange}
          onBlur={handleBlur}
        />
        <Field
          id="message"
          type="textarea"
          component="textarea"
          placeholder="Message *"
          error={touched.message && errors.message}
          value={values.message}
          onChange={handleChange}
          onBlur={handleBlur}
        />
        <Button
          type="submit"
          onClick={handleSubmit}
          margin={"1rem 0 0"}
        >
          {isSubmitting ? 'Sending...' : 'Contact us'}
        </Button>
      </form>
    </Layout>

  )

}


export default formikWrapper(ContactForm)
