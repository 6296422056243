import React from 'react';
import styled from 'styled-components';

import theme from 'theme';
import Layout from 'Layout';
import List from 'List';
import Card from 'Card';
import Typography from 'Typography';

// import placeholder from 'static/images/placeholder-square.png';

const Grid = styled(Card)`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 2px;

  @media (max-width: ${theme.device.small}) {
    grid-template-columns: repeat(1, 1fr);
  }

`;

const Item = styled.li`
  width: 100%;
  position: relative;
  font-size: 0;
  margin-bottom: 2px;

  &:nth-child(2) ${Grid}{
    direction: rtl;
  }
`;

const ItemText = styled(Card)`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${theme.color.grey.lightest};

  @media (max-width: ${theme.device.small}) {
    padding: 5rem 0;
  }
`;

const ItemImage = styled.img`
  position: relative;
  width: 100%;
  height: auto;
  background-color: ${theme.color.grey.light};
  object-fit: contain;
`;

const AddressTypography = styled(Typography)`
  margin-top: 0.5rem;

  p {
    margin: 0;

    @media (min-width: ${theme.device.large}) {
      line-height: 1.8rem;

      &:first-child {
        margin-top: 1rem;
      }
    }
  }
`;

export default class extends React.Component {

  render() {

    let data = this.props.data.acf.contact_locations; // Initial data

    return (

      <Layout>
        <List block>
          {data.map((item,i) =>
            <Item key={i}>
              <Grid>
                <Card>
                  <ItemText>
                    <Typography
                      display3
                      bold
                      noMargin
                    >
                      {item.heading}
                    </Typography>
                    <AddressTypography
                      div
                      center
                      dangerouslySetInnerHTML={{ __html:item.address}}
                    />
                  </ItemText>
                </Card>
                <Card>
                  <ItemImage
                    src={item.image.sizes.grid2x}
                    width="1200"
                    height="1200"
                    alt="Inspire Group"
                  />
                </Card>
              </Grid>
            </Item>
          )}
        </List>
      </Layout>

    );

  }

}
