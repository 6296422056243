import React from 'react';

import getFetchJson from 'Response';

import SiteTitle from 'SiteTitle';
import Menu from 'Menu';
import HomeTextPanel from 'HomeTextPanel';
import HeroPanel from 'HeroPanel';
import ColumnsPanel from 'ColumnsPanel';
import CarouselMultipleSlides from 'CarouselMultipleSlides';
import ContactPanelWithParallax from 'ContactPanelWithParallax';
import LogoGridPanel from 'LogoGridPanel';
import Footer from 'Footer';

class Home extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoaded: false,
      data: [],
    };
  }

  getJson = () => {

    const data = getFetchJson( 'home', 'Global' )

    return Promise.all([data])
      .then((json) => {
        this.setState({
          data: json[0],
          isLoaded: true,
        })
      })

  }

  componentDidMount() {

    this.getJson()

  }

  render() {

    const {
      error,
      isLoaded,
      data,
      } = this.state;

    if (error) {
      return <div>Error: {error.message}</div>;
    } else if (!isLoaded) {
      return null;
    } else {

      return (
        <main>
          <SiteTitle>Inspire Group</SiteTitle>
          <Menu data={data.menus} transparent border />
          <HeroPanel data={data.home} />
          <HomeTextPanel data={data.home} />
          <ColumnsPanel data={data.home} />
          <CarouselMultipleSlides title={'Our work'} data={data.options} />
          <ContactPanelWithParallax data={data.home} />
          <LogoGridPanel data={data.options} />
          <Footer />
        </main>
      );
    }
  }
}

export default Home;
