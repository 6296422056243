import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'

import AliceCarousel from 'react-alice-carousel'
import 'static/styles/alice-carousel.css'

import Layout from 'Layout'
import Card from 'Card'
import Typography from 'Typography'
import Image from 'Image'

const SlideContent = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`

const SlideOverlay = styled(Card)`
  opacity: 0;
  position: absolute;
  top: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  border-radius: 0.5rem;
  background-color: rgba(0, 0, 0, 0.8);
  transition: opacity 0.2s linear;
`

const SlideImage = styled(Image)`
  border-radius: 0.5rem;
  height: 100%;
`

// This slide is basically a container - any styling done on internal elements
const Slide = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  border-radius: 0.5rem;
  justify-content: center;
  font-size: 0;
  position: relative;
  padding: 0 1rem;

  &:hover ${SlideOverlay} {
    opacity: 1;
  }

  font-size: 1rem;
`

// Data is being loaded async why?
// Does it avoid some of the issues needing a retrigger
// of ... for responsive similar to nuka issues?
// Does this essentially lazy load? no..?
class CarouselMultipleSlides extends React.Component {

  constructor(props) {

    super(props);
    this.state = {
      carouselData: [],
    }

  }

  setStateAsync(state) {
    return new Promise((resolve) => {
      this.setState(state, resolve)
    })
  }

  async componentDidMount() {

    const data = await this.props.data.carousel;

    await this.setStateAsync(
      {
        carouselData: data,
      }
    )

  }

  render() {

    const { carouselData } = this.state

    // These settings and responsive objects could be moved external file?
    const responsive = {
      0: {
          items: 1
      },
      400: {
          items: 2
      },
      700: {
          items: 3
      },
      900: {
          items: 4
      },
      1200: {
          items: 6
      },
    }

    const settings = {
      autoPlay: false,
      buttonsDisabled: true,
      duration: 400,
      responsive: responsive,
      startIndex: 1,
    }

    return (
      <Layout style={{padding: '12rem 0 8rem 0'}}>
        <Card margin={'0 0 4rem 0'}>
          <Typography
            display3
            heading2
            centre
            bold
            noMargin
          >
            {this.props.title}
          </Typography>
          <Typography centre >
            Check out some of our work we are proud of.
          </Typography>
        </Card>
        <AliceCarousel {...settings}>
          {carouselData.map((item,i) =>
            <Slide key={i}>
              <Link to={'/case-study/' + item.case_study.post_name}>
                <SlideContent>
                  <SlideImage
                    rounded
                    src={item.image.sizes.carousel2x}
                    width='230'
                    height='200'
                   />
                  <SlideOverlay>
                    <Typography
                      colorWhite
                      bold
                      centre
                      noMargin
                    >
                      {item.heading}
                    </Typography>
                    <Typography
                      colorWhite
                      display6
                      centre
                      noMargin
                      paddingLR
                    >
                      {item.description}
                    </Typography>
                  </SlideOverlay>
                </SlideContent>
              </Link>
            </Slide>
          )}
        </AliceCarousel>
      </Layout>

    )

  }

}

export default CarouselMultipleSlides
