import React from 'react';
import styled from 'styled-components';

import theme from 'theme';
import Layout from 'Layout';
import Image from 'Image';

const Panel = styled(Layout)`
  background-color: ${theme.color.grey.lightest};
  font-size: 0;
`;

const Grid = styled.ul`
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-template-columns: 1fr 200px 200px 200px 200px 1fr;
  column-gap: 0;
  gap: 1px;
  border: 1px solid ${theme.color.grey.lightest};

  @media (max-width: ${theme.device.large}) {
    grid-template-columns: 25% 25% 25% 25%;
  }

  @media (max-width: ${theme.device.small}) {
    grid-template-columns: 50% 50%;
  }

`;

const Media = styled.div``;

const Item = styled.li`
  background-color: ${theme.color.white};

  ${Media} {
    display: flex;
    justify-content: center;
    width: 200px;
    height: 124px;

    img {
      align-self: center;
    }

    @media (max-width: ${theme.device.large}) {
      width: 100%;
    }
  }

  &:nth-child(6n+1) ${Media} {
    float: right;
    @media (max-width: ${theme.device.large}) {
      float: none;
    }
  }

  &:nth-child(6n) ${Media} {
    float: left;
    @media (max-width: ${theme.device.large}) {
      float: none;
    }
  }
`;


export default class extends React.Component {

  render() {

    const carouselData = this.props.data.footer_partners_grid;

    return (

      <Panel>
        <Grid>
          {carouselData.map((item,i) =>
            <Item key={i}>
              <Media>
                <Image data={item.image} height='124' width='200' />
              </Media>
            </Item>
          )}
        </Grid>
      </Panel>

    );

  }

}
