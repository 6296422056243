let siteUrl
let endpointUrl

if (process.env.REACT_APP_ENV === 'staging') {
  // Staging build
  siteUrl = process.env.REACT_APP_URL_STAGING
  endpointUrl = process.env.REACT_APP_API_URL_STAGING
} else if (process.env.REACT_APP_ENV === 'development') {
  // Local build
  siteUrl = process.env.REACT_APP_URL_LOCAL
  endpointUrl = process.env.REACT_APP_API_URL_LOCAL
} else {
  if (process.env.NODE_ENV === 'development') {
    siteUrl = process.env.REACT_APP_URL_LOCAL
    endpointUrl = process.env.REACT_APP_API_URL_LOCAL
  } else {
    siteUrl = process.env.REACT_APP_URL_PRODUCTION
    endpointUrl = process.env.REACT_APP_API_URL_PRODUCTION
  }
}


// Set where data is served from, and avoid CORS issues
let fetchPath
if ( process.env.REACT_APP_ENV === 'production' ) {
  fetchPath = 'wp-content/uploads/data'; // WPEngine
} else if
// Either public directory on localhost
// Or served from same origin
 (  !process.env.NODE_ENV === 'development'
    || process.env.REACT_APP_ENV === 'development'
    || process.env.REACT_APP_ENV === 'staging'
  ) {
    fetchPath = 'app/uploads/data'
}


export const config = {
  siteUrl: siteUrl,
  apiUrl: endpointUrl,
  apiBaseUrl: 'api',
  apiCustomRouteBase: 'better-rest-endpoints/v1',
  fetchUrl: fetchPath,
}

export const api = `${config.apiUrl}/${config.apiBaseUrl}/${config.apiCustomRouteBase}/`
export const url = `${config.siteUrl}`
export const fetchUrl = `${config.siteUrl}/${config.fetchUrl}/`

if ( !process.env.NODE_ENV === 'production' ) {
  console.log('Site: ' + url)
  console.log('API: ' + api)
  console.log('fetch: ' + fetchUrl)
}
