import React from 'react'

import SiteTitle from 'SiteTitle'
import Menu from 'Menu'
import Header from 'Header'
import Builder from 'Builder'
import LogoGridPanel from 'LogoGridPanel'
import Footer from 'Footer'

import getFetchJson from 'Response';

class Page extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      error: null,
      isLoaded: false,
      data: [],
      dataGlobal: [],
    }
  }

  getJson = (slug) => {

    const pageData = getFetchJson( slug, 'Page' )
    const globalData = getFetchJson( null, 'Global' )

    return Promise.all([pageData, globalData])
      .then((json) => {
        this.setState({
          data: json[0],
          dataGlobal: json[1],
          isLoaded: true,
        })
      })

  }

  componentDidMount() {

    const slug = this.props.match.params.id
    this.getJson(slug)

  }

  componentDidUpdate(prevProps) {

    if ( prevProps.match.params.id !== this.props.match.params.id) {
      const slug = this.props.match.params.id
      this.getJson(slug)
    }

  }

  render() {

    const {
      error,
      isLoaded,
      data,
      dataGlobal,
    } = this.state;

    if (error) {
      return <div>Error: {error.message}Error</div>;
    } else if ( !isLoaded ) {
      return null;
    } else {

      return (

        <main>
          <SiteTitle>Inspire Group</SiteTitle>
          <Menu data={dataGlobal.menus} />
          <Header data={data} />
          <Builder data={data}/>
          <LogoGridPanel data={dataGlobal.options} />
          <Footer />
        </main>
      )

    }

  }

}

export default Page
