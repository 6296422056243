import React from 'react'

import SiteTitle from 'SiteTitle'
import Menu from 'Menu'
import Header from 'Header'
import Builder from 'Builder'
import TitlePanel from 'TitlePanel'
import PeopleGrid from 'PeopleGrid'
import LogoGridPanel from 'LogoGridPanel'
import Footer from 'Footer'
import getData from 'Data'

import BuilderAccordionPanel from 'BuilderAccordionPanel'

class WhoWeAre extends React.Component {

  render() {

    const {
      data,
      dataAllPeople,
      dataGlobal,
    } = this.props;

    return (
      <main>
        <SiteTitle>Inspire Group</SiteTitle>
        <Menu data={dataGlobal.menus} />
        <Header data={data} />
        <Builder data={data}/>
        <TitlePanel style={{padding: '2rem 0'}}>Meet the team</TitlePanel>
        <PeopleGrid data={dataAllPeople}/>
        <BuilderAccordionPanel data={data}/>
        <LogoGridPanel data={dataGlobal.options} />
        <Footer />
      </main>
    )

  }

}

export default getData('who-we-are', 'WhoWeAre')(WhoWeAre)
