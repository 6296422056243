import React from 'react';
import styled from 'styled-components';
import Modal from 'Modal';
import { Player } from 'video-react';

import theme from 'theme';
// import Layout from 'Layout';
import Button from 'Button';
import Cross from '../elements/icons/Cross';

import 'static/styles/video-react.css';

const VideoModal = styled(Modal)`
  ${'' /* background-color: rgba(0, 0, 0, 0.2); */}
`;

const VideoModalContent = styled.div`
  width: 75%;
  height: auto;
`;

const VideoPlayer = styled(Player)`
`;

const IconClose = styled(Cross)`
  background-color: transparent;
  height: 20px;
  transition: all 0.3 ease-in-out;
  display: inline-block;
  position: absolute;
  top: 2rem;
  right: 2rem;
  color: ${theme.color.white};

  &:hover {
    cursor: pointer;
    color: ${theme.color.mint.primary};
  }
`;

export default class extends React.Component {

  constructor (props) {
    super(props)

    this.state = {
      isOpen: false,
    }

    this.toggleModal = this.toggleModal.bind(this)
  }

  toggleModal (e) {
    this.setState({ isOpen: !this.state.isOpen })
  }

  render() {

    return (
      <div>
        <Button
          onClick={this.toggleModal}
          margin={"1rem 0 0"}
        >
          Watch video
        </Button>
        <VideoModal
          isOpen={this.state.isOpen}
          onBackgroundClick={this.toggleModal}
          onEscapeKeydown={this.toggleModal}
        >
          <VideoModalContent>
            <VideoPlayer
              autoPlay={true}
              muted={true}
              src={this.props.src}
            />
            <IconClose onClick={this.toggleModal}/>
          </VideoModalContent>
        </VideoModal>
      </div>

    );

  }

}
