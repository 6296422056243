import React from 'react';
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import styled from 'styled-components';

import theme from 'theme';
import Layout from 'Layout';
import List, { Item } from 'List';
import Typography from 'Typography';
import PrimaryLogo from '../elements/icons/Logo';

const Footer = styled(Layout)`
  display: flex;
  background-color: ${theme.color.grey.lightest};
  background-color: rgba(230, 231, 239, 0.3);
  background-color: #f8f8fa; // Temp hack
  padding: 2rem 0 4rem 0;

  @media (max-width: ${theme.device.medium}) {
    flex-direction: column;
  }
`;

const FooterList = styled(List)`
  li {
    line-height: 1.8rem;
  }

  @media (max-width: ${theme.device.small}) {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
`;

const Logo = styled(PrimaryLogo)`
  height: auto;
  width: 160px;

  color: ${theme.color.black};
  transition: fill 0.3 ease-in-out;
`;

const LogoText = styled.span`
  display: none;
`;

const LogoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex-basis: 50%;

  @media (max-width: ${theme.device.small}) {
    flex-basis: 100%;
    margin: auto;
    text-align: center;
  }
`;

const MenuContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-basis: 50%;
  justify-content: space-between;

  @media (max-width: ${theme.device.small}) {
    flex-basis: 100%;
    flex-direction: column;
    margin: auto;
    text-align: center;
  }

`;

export default class extends React.Component {

  render() {

    return (
      <Footer container>
        <LogoContainer>
          <Link to={'/'}>
            <Logo/>
            <LogoText>
              Inspire Group
            </LogoText>
          </Link>
          <Typography
          >
            Inspire Group 2018
            <br />
            Copyright &copy;
          </Typography>
        </LogoContainer>
        <MenuContainer>
          <FooterList block>
            <Item>
              <Typography
                bold
                noMargin
              >
                Company
              </Typography>
            </Item>
            <Item>
              <Link to={'/who-we-are'}>
                Who We Are
              </Link>
            </Item>
            <Item>
              <Link to={'/portfolio'}>
                Portfolio
              </Link>
            </Item>
            <Item>
              <Link to={'/blog'}>
                Blog
              </Link>
            </Item>
            <Item>
              <HashLink smooth to={'/who-we-are#careers'}>
                Careers
              </HashLink>
            </Item>
          </FooterList>
          <FooterList block>
            <Item>
              <Typography
                bold
                noMargin
              >
                Privacy
              </Typography>
            </Item>
            <Item>
              <Link to={'/'}>
                Privacy Policy
              </Link>
            </Item>
          </FooterList>
          <FooterList block>
            <Item>
              <Typography
                bold
                noMargin
              >
                Contact
              </Typography>
            </Item>
            <Item>
              <Link to={'/contact'}>
                Wellington
              </Link>
            </Item>
            <Item>
              <Link to={'/contact'}>
                Melbourne
              </Link>
            </Item>
            <Item>
              <Link to={'/contact'}>
                Auckland
              </Link>
            </Item>
          </FooterList>
        </MenuContainer>
      </Footer>
    );

  }

}
