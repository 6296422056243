const filters = [
  {
    name: 'All',
    slug: 'all',
  },
  {
    name: 'Wellington',
    slug: 'wellington',
  },
  {
    name: 'Melbourne',
    slug: 'melbourne',
  },
  {
    name: 'Auckland',
    slug: 'auckland',
  },
];

export default filters;
