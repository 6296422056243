const filters = [
  {
    name: 'All',
    id: '1',
    slug: 'all',
  },
  {
    name: 'Blended',
    id: '10',
    slug: 'blended',
  },
  {
    name: 'Compliance',
    id: '11',
    slug: 'compliance',
  },
  {
    name: 'eLearning',
    id: '12',
    slug: 'elearning',
  },
  {
    name: 'Leadership',
    id: '13',
    slug: 'leadership',
  },
];

export default filters;
